import { initializeApp } from 'firebase/app'
import { getFirestore, collection, doc, getDocs, setDoc, getDoc, addDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import 'firebase/firestore'

// Initialize Firestore
const firebaseConfig = {
  apiKey: "AIzaSyDk0XkreoTRw97oAm49-Kf3YomP9rDFSIY",
  authDomain: "tue-game-dev-website.firebaseapp.com",
  projectId: "tue-game-dev-website",
  storageBucket: "tue-game-dev-website.appspot.com",
  messagingSenderId: "588133042923",
  appId: "1:588133042923:web:0faa17bc0cf09fc0a9ccae"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)


// Authentication
// Clears newly registered users of their writing rights
export async function initUser(user) {
  await setDoc(doc(db, 'users', user.uid), {
    admin: false
  })
}

// Check if a user has admin rights
export async function checkAdmin(uid) {
  const docSnap = await getDoc(doc(db, 'users', uid))
  if (docSnap.exists()) {
    const docData = docSnap.data()
    if (docData && docData.admin === true) {
      return true
    }
    return false
  }

  // The user doesn't even exist in the users table!
  return false
}


// Module generalizations
async function getList(modelName, callback) {
  const col = collection(db, modelName)
  const unsub = onSnapshot(col, snapshot => {
    const list = snapshot.docs.map(doc => (
      {
        id: doc.id,
        ...doc.data()
      }
    ))
    callback(list)
  })

  return unsub
}

async function getItem(modelName, id) {
  const docRef = doc(db, modelName, id)
  const docSnap = await getDoc(docRef)
  if (docSnap && docSnap.exists()) {
    let docData = docSnap.data()
    docData.id = id
    return docData
  }

  return null
}

async function updateItem(modelName, id, item) {
  await setDoc(doc(db, modelName, id), item)
}

async function createItem(modelName, item) {
  const docRef = await addDoc(collection(db, modelName), item)
  return docRef.id
}

async function deleteItem(modelName, id) {
  await deleteDoc(doc(db, modelName, id))
}


// Events module
export function getEventsList(callback) {
  return getList('events', callback)
}

export function getEvent(id) {
  return getItem('events', id)
}

export function updateEvent(id, event) {
  return updateItem('events', id, event)
}

export function createEvent(event) {
  return createItem('events', event)
}

export function deleteEvent(id) {
  return deleteItem('events', id)
}


// Games module
export function getGamesList(callback) {
  return getList('games', callback)
}

export function getGame(id) {
  return getItem('games', id)
}

export function updateGame(id, game) {
  return updateItem('games', id, game)
}

export function createGame(game) {
  return createItem('games', game)
}

export function deleteGame(id) {
  return deleteItem('games', id)
}

export async function getGameMediaByIndex(game, mediaIndex) {
  const storage = getStorage(app)
  const url = await getDownloadURL(ref(storage, game.media[mediaIndex].path))
  return url
}


export default db;

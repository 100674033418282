<template>
  <div id="page-overlay"></div>
  <FrontendOverview v-if="isFrontEnd">
    <router-view />
  </FrontendOverview>
  <router-view v-else />

  <transition-group name="toast-notification" tag="div" class="toast-notifications">
    <ToastNotification
      v-for="item in notifications"
      :key="item.id"
      :id="item.id"
      :type="item.type"
      :title="item.title"
      :message="item.message"
      :duration="item.duration"
      @destroy="() => destroyNotification(item.id)"
    />
  </transition-group>

  <div class="dialog-wrapper">
    <Dialog
      v-for="item in dialogs"
      :key="item.id"
      :id="item.id"
      :type="item.type"
      :title="item.title"
      :message="item.message"
      :options="item.options"
      :controlDimmer="item.controlDimmer"
      @destroy="() => destroyDialog(item.id)"
    />
  </div>
</template>

<script setup>
import useNotifications from './notifications'
import useDialogs from './dialogs'
import { useDevLog } from './util'
import { provide } from 'vue'

const {
  notifications,
  createNotification,
  destroyNotification
} = useNotifications()

const {
  dialogs,
  createDialog,
  destroyDialog
} = useDialogs()

const { devLog } = useDevLog()

provide("createNotification", createNotification)
provide("createDialog", createDialog)
provide("devLog", devLog)
</script>

<script>
import ToastNotification from '@/components/ui/ToastNotification.vue'
import Dialog from '@/components/ui/Dialog.vue'
import FrontendOverview from '@/components/FrontendOverview.vue'

export default {
  name: 'App',
  components: {
    FrontendOverview,
    ToastNotification,
    Dialog,
  },
  data() {
    return {
      backendNames: ['Login', 'Dashboard', 'LoginSojourn']
    }
  },
  computed: {
    isFrontEnd() {
      return !this.$route.meta.admin
    },
  },
}
</script>

<style lang="scss">
// Imports
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

// General styling
*, *::before, *::after {
  margin: 0; box-sizing: border-box;
}

html {
  --page-padding: 15%;
  --navbar-height: 5rem;
  --footer-height: 5rem;
  --color-bg-dark: #1e1d24;
  --color-bg: #292835;
  --color-bg-light: #525163;
  --color-text-light: #817F98;
  --color-purple: #8d3ec9;
  --color-purple-light: #a25dd6;
  --color-purple-lightest: #c998ee;
  --color-red: #ff5252;
  --color-red-light: #ff6f6f;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-bg-dark);
  font: 17px "Rubik", sans-serif;
  overflow-x: hidden;
  color: #fff;

  // Keep footer always at the bottom
  position: relative;
  min-height: 100vh;
}

body.overflow-hidden {
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  html {
    --page-padding: 10%;
  }
}

h1, h2, h3, strong, b { font-weight: 500; }

section { padding: 5rem var(--page-padding); }
section h1 { font-size: 1.75em; }
section.middle { padding-top: 2.5rem; }

#page-overlay {
  //display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #131419;
  opacity: 0;
  transition: opacity 200ms ease;
  pointer-events: none;
  z-index: 10;

  &.active {
    opacity: 0.6;
    pointer-events: auto;

    &.dark { opacity: 0.8; }
  }
}

.link { color: var(--color-purple-lightest); }
a.hover-link-style {
  color: inherit;
  text-decoration: none;
  transition: color 150ms ease;

  &:hover {
    color: var(--color-purple-lightest);
  }
}

// Keep footer always at the bottom
.content-wrapper { padding-bottom: var(--footer-height); }


// -------------------------------------------
// Layout: Light containers
// -------------------------------------------
.container-light {
  background-color: var(--color-bg-light);
  border-radius: 6px;
}

// -------------------------------------------
// Layout: Columm layouts
// -------------------------------------------
.row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  &.centered {
    justify-content: center;
  }

  // Center-aligning columns
  &.content-centered, .centered {
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .row {
    display: block;

    .col { max-width: 100%; }
    .col + .col { margin-top: 4rem; }
  }
}

// -------------------------------------------
// Components: Buttons
// -------------------------------------------
.button {
  font: 15px "Rubik", sans-serif;
  display: inline-block;
  padding: 12px 1.25rem;
  background-color: var(--color-purple);
  color: #fff;
  text-decoration: none;
  transition: 200ms ease;
  border-radius: 4px;
  border: 1px solid var(--color-purple);
  outline: none;

  &:hover, &:focus {
    background-color: var(--color-purple-light);
    border-color: var(--color-purple-light);
  }

  &:focus {
    border-color: var(--color-purple-lightest);
  }

  &.round {
    border-radius: 1000px;
    padding: 8px 1.25rem;
  }

  &.disabled {
    background-color: transparent;
    border-color: #8D8CA2;
    color: #8D8CA2;
  }
}

// -------------------------------------------
// Components: Notifications
// -------------------------------------------
.toast-notifications {
  z-index: 100;
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.8rem;
}

.toast-notification-enter-active {
  animation: toast-fade-in 0.5s ease-in-out;
}
.toast-notification-leave-active {
  animation: toast-fade-in 0.5s ease-in-out reverse;
}

@keyframes toast-fade-in {
  from {
    opacity: 0;
    transform: scale(0.4);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

// -------------------------------------------
// Components: Cursor card
// -------------------------------------------
.card-section {
  padding-bottom: 0;
}
.card-container {
  display: flex;
  gap: 2rem;
  border-radius: 6px;
  padding: 2rem 2.5rem;
  word-spacing: 0.05em;
  letter-spacing: 0.01em;
  line-height: 1.75em;

  strong {
    font-size: 1.2em;
  }

  i {
    font-size: 4em;
  }
}

@media screen and (max-width: 700px) {
  .card-container {
    display: block;
    i {
      margin-bottom: 1rem;
      font-size: 3em;
    }
  }

  .card-section {
    padding-bottom: 2.5rem;
  }
}

// Z-index usage:
// 00-09: Simple layering (text over images)
// 10: Dark overlay layer
// 11: Navigation layer (mobile navscreen)
// 19: Dialogs
// 20: Toast notifications
</style>

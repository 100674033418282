<template>
  <div :class="['dialog', type]">
    <div class="dialog-border"></div>
    <div class="dialog-icon">
      <i :class="[`fal fa-lg fa-${iconFromType}`]"></i>
    </div>
    <div>
      <div class="dialog-text">
        <strong>{{ title }}</strong>
        <p v-html="message"></p>
      </div>
      <div class="dialog-options">
        <button :class="['button', opt.type]" v-for="opt in options" :key="opt.type" @click="() => selectOption(opt)">{{ opt.text }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { toggleDimmer } from '@/util'

export default {
  name: "Dialog",
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "alert",
      required: true
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    message: {
      type: String,
      default: "Oops! A message was not provided.",
      required: false
    },
    options: {
      type: Array,
      default: [
        {
          type: 'confirm',
          text: 'Confirm',
          callback: () => {}
        },
        {
          type: 'cancel',
          text: 'Cancel',
          callback: () => {}
        }
      ],
      required: true
    },
    controlDimmer: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  emits: ['destroy'],
  data() {
    return {
      body: null,
      overlay: null
    }
  },
  computed: {
    iconFromType() {
      switch (this.type) {
        case 'alert':
          return 'exclamation-triangle'
        case 'alert-warning':
          return 'exclamation-circle'
        case 'alert-warning-remove':
          return 'trash'
        default:
          return 'info-circle'
      }
    },
    dialogTitle() {
      return this.title && this.title !== null ? this.title : "Dialog"
    }
  },
  methods: {
    selectOption(option) {
      // Handle option
      option.callback()

      // Close the overlay if specified
      if (this.controlDimmer) {
        this.closeOverlay()
      }

      // Destroy dialog
      this.$emit("destroy")
    },
    openOverlay() {
      // If the dialog has a cancel option, run it when the overlay is clicked
      const cancelOption = this.options.find(option => option.type === 'cancel')
      if (cancelOption !== undefined) {
        toggleDimmer(true, () => {
          this.selectOption(cancelOption)
        })
      } else {
        toggleDimmer(true)
      }
    },
    closeOverlay() {
      toggleDimmer(false)
    }
  },
  mounted() {
    if (this.controlDimmer) {
      this.openOverlay()
    }
  }
}
</script>

<style lang="scss">
.dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 500px;
  padding: 2rem;
  background-color: var(--color-bg-light);
  border-radius: 1rem;
  z-index: 19;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  &-icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    z-index: 2;
    background-color: var(--color-bg-light);
  }

  &.alert-warning, &.alert-warning-remove {
     border-color: var(--color-red);

    .dialog-icon {
      background-color: var(--color-red);
    }

    .button.confirm {
      background-color: var(--color-red);
      border-color: var(--color-red);

      &:hover, &:focus {
        background-color: var(--color-red-light);
        border-color: var(--color-red-light);
      }
    }
  }

  &-text {
    margin: 0 3rem 1.25rem;
    line-height: 1.5;

    strong {
      display: block;
      margin-bottom: 4px;
      font-size: 20px;
    }
  }

  .button {
    padding: 8px 1rem;
    font-weight: 500;
    cursor: pointer;

    & + .button {
      margin-left: 1rem;
    }

    &.cancel {
      background-color: transparent;
      color: var(--color-text-light);
      border-color: var(--color-text-light);

      &:hover, &:focus {
        color: #aaa;
        border-color: #aaa;
      }
    }
  }
}
</style>
import { createRouter, createWebHistory } from 'vue-router'
import { checkForAuth } from './util'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('../views/Events.vue')
  },
  {
    path: '/games-showcase',
    name: 'GamesShowcase',
    component: () => import('../views/GamesShowcaseOverview.vue')
  },
  {
    path: '/game/:gameId',
    name: 'GamesShowcaseSingle',
    component: () => import('../views/GamesShowcaseSingle.vue')
  },
  {
    path: '/game/:gameId/play',
    name: 'GamesShowcasePlay',
    component: () => import('../views/GamesShowcasePlay.vue')
  },
  {
    path: '/join-us',
    name: 'Recruitment',
    component: () => import('../views/Recruitment.vue'),
    beforeEnter(to, from, next) {
      if (store.state.settings.activeOptionalPages.recruitment) {
        next()
      } else {
        next('/not-found')
      }
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/admin/Login.vue'),
    meta: {
      admin: true,
    }
  },
  {
    path: '/login-sojourn',
    name: 'LoginSojourn',
    component: () => import('../views/admin/LoginSojourn.vue'),
    meta: {
      admin: true,
      authRequired: true
    }
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: () => import('../views/admin/Dashboard.vue'),
    meta: {
      admin: true,
      authRequired: true,
      adminRequired: true
    }
  },
  {
    path: '/admin/events',
    name: 'AdminEvents',
    component: () => import('../views/admin/Events.vue'),
    meta: {
      admin: true,
      authRequired: true,
      adminRequired: true
    }
  },
  {
    path: '/admin/games',
    name: 'AdminGames',
    component: () => import('../views/admin/Games.vue'),
    meta: {
      admin: true,
      authRequired: true,
      adminRequired: true
    }
  },

  // pathMatch is the name of the param, e.g., going to /not/found yields
  // { params: { pathMatch: ['not', 'found'] }}
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/Error.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Checking before loading the route whether the user has the correct permissions
router.beforeEach(checkForAuth)

export default router

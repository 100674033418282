import { ref } from 'vue'

const defaultNotificationOptions = {
  type: "info",
  title: "Info notification",
  message: "Oops! A message was not provided.",
  duration: 5
}

export default function useNotifications() {
  const notifications = ref([])

  const createNotification = (options) => {
    const fOptions = Object.assign({ ...defaultNotificationOptions}, options)
    notifications.value.push(
      {
        id: createUUID(),
        ...fOptions,
      },
    )
  }

  const destroyNotification = (id) => {
    const index = notifications.value.findIndex((item) => item.id === id)
    if (index !== -1) notifications.value.splice(index, 1)
  }

  return {
    notifications,
    createNotification,
    destroyNotification
  }
}

// Utility: Generate UUIDs
function createUUID() {
  let dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}
<template>
  <footer class="footer">
    <div class="copy">
      <p>&copy; Totem Game Dev, 2023. All rights reserved.</p>
    </div>

    <div class="logo">
      <img src="../assets/totem-logo.png" alt="">
    </div>

    <div class="social-items">
      <a href="https://www.twitter.com/totemgamedev/" target="_blank" class="item"><i class="fab fa-twitter"></i></a>
      <a href="https://www.instagram.com/totemgamedev/" target="_blank" class="item"><i class="fab fa-instagram"></i></a>
      <a href="https://discord.gg/gyWPKSUBJc" target="_blank" class="item"><i class="fab fa-discord"></i></a>
      <a href="https://www.linkedin.com/company/totem-game-dev/" target="_blank" class="item"><i class="fab fa-linkedin"></i></a>
    </div>

    <div class="copy copy-mobile">
      <p>&copy; Totem Game Dev, 2023. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss">
.footer {
  // Keep footer always at the bottom
  position: absolute;
  bottom: 0;

  display: flex;
  width: 100%;
  height: var(--footer-height);;
  align-items: center;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
  background-color: var(--color-bg);
  border-top: 2px solid #445;
  color: #aaa;

  & > * {
    flex: 1;
    display: flex;
    justify-content: center;

    &:first-child > span { margin-right: auto; }
    &:last-child > span { margin-left: auto; }
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 200ms ease;

    & + a { margin-left: 24px; }
    &:hover { color: #fff; }
  }

  .copy { font-size: 16px; }
  .copy-mobile { display: none; }
  .social-items a { font-size: 20px; }
  .logo img { height: 2rem; }
}

@media screen and (max-width: 600px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    & > * { flex: initial; }
    & .copy,
    & .logo { display: none; }
    & .social-items { align-items: center; }

    & .copy.copy-mobile {
      display: block;
      font-size: 12px;
      text-align: center;
    }
  }
}
</style>